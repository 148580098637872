<template>
  <section class="regions">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="fetchList" @handleAdd="handleAdd"></custom-filter>
      <b-card>
        <b-table :items="datas" :fields="fields">
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="warning" size="sm" @click="$router.push({name: 'RegionsChildren',params:{id:data.item.id}})">
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button variant="primary" size="sm" class="ml-1" @click="handleEdit(data.item.id)" >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="lg" hide-footer :title="modalTitle" v-model="modalVisible" >
      <Form :id="id" @saved="fetchList" />
    </b-modal>
  </section>
</template>

<script>
import {getRegions, deleteRegion} from '@/api/region';
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import Form from '@/views/region/Form'

const actions = {
  get: getRegions,
  delete: deleteRegion,
}


export default {
  name: 'index',
  components:{
    CustomFilter,
    Form,
  },
  mixins:[
    listTemplate,
  ],
  data() {
    return {
      actions,
      fields: [
        {key:'id', label: this.$t('ID')},
        {key:'region_uz', label: this.$t('message.RegionUz')},
        {key:'region_ru', label: this.$t('message.RegionRu')},
        {key:'region_en', label: this.$t('message.RegionEn')},
        {key:'action', label: this.$t('message.Action')},
      ],
      filters: {
        nomination_id: this.$route.params.id,
      }
    }
  },
}
</script>

<style scoped>

</style>
